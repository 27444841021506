import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative z-40 flex justify-between items-center bg-white border-b border-[#e7e8ed]" }
const _hoisted_2 = { class: "hidden sm:block px-12 text-xl overflow-x-hidden whitespace-nowrap overflow-ellipsis max-w-full" }
const _hoisted_3 = { class: "sm:hidden" }
const _hoisted_4 = { class: "flex items-center" }
const _hoisted_5 = {
  key: 0,
  class: "hidden sm:flex items-center space-x-3 px-4 h-20 border-r border-background"
}
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "hidden sm:flex items-center space-x-3 mx-4" }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SVG = _resolveComponent("SVG")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ContextMenu = _resolveComponent("ContextMenu")!
  const _component_Notifications = _resolveComponent("Notifications")!
  const _directive_click_away = _resolveDirective("click-away")!

  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.title), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.toggleSidebar && _ctx.toggleSidebar(...args)), ["prevent","stop"])),
        class: "grid place-items-center px-7 h-20 text-black hover:bg-dark hover:bg-opacity-5 border-r border-background transition cursor-pointer"
      }, [
        _createElementVNode("div", null, [
          _createVNode(_component_SVG, {
            name: "icons/menu",
            class: "w-5 h-5 text-gray"
          })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.options.length && _ctx.$user.can('create tasks'))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: `option-${index}`,
                onClick: ($event: any) => (_ctx.handleOnOptionClick(option.action)),
                class: "py-2 px-3 hover:bg-gray-100 cursor-pointer rounded-md transition"
              }, _toDisplayString(option.title), 9, _hoisted_6))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nav, (link, index) => {
          return (_openBlock(), _createBlock(_component_router_link, {
            key: `link-${index}`,
            to: { name: link.name },
            class: _normalizeClass(["py-2 px-3 hover:bg-gray-100 rounded-md transition", { 'text-blue-800 bg-blue-200 hover:bg-blue-300 ': link.name == _ctx.currentRoute }])
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(link.title), 1)
            ]),
            _: 2
          }, 1032, ["to", "class"]))
        }), 128))
      ]),
      (_ctx.contextMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _withDirectives(_createElementVNode("div", {
              title: "More",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.contextMenu.active = !_ctx.contextMenu?.active)),
              class: "grid place-items-center px-7 h-20 text-black hover:bg-dark hover:bg-opacity-5 border-l border-background transition cursor-pointer"
            }, [
              _createElementVNode("div", null, [
                _createVNode(_component_SVG, {
                  name: "icons/more",
                  class: "w-4 h-1 text-gray"
                }),
                _createVNode(_component_ContextMenu)
              ])
            ], 512), [
              [_directive_click_away, () => (_ctx.contextMenu.active = false)]
            ])
          ]))
        : _createCommentVNode("", true),
      _createVNode(_component_Notifications)
    ])
  ]))
}